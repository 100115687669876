<template>
  <trac-loading v-if="loading" />
  <main v-else>
    <div class="flex items-center justify-between">
      <h1 class="text-sm font-medium">Sales Agent Management</h1>
      <div v-if="feature" class="flex gap-8 items-center">
        <p
          @click="switchStoreModal = true"
          class="cursor-pointer text-primaryBlue text-xs font-medium"
        >
          Switch Stores
        </p>
        <trac-button
          @click.native="createSalesAgentModal = true"
          class="uppercase"
        >
          Create Sales Agent
        </trac-button>
      </div>
    </div>
    <div class="big-shadow p-6 rounded-lg mt-6">
      <div v-if="feature" class="mt-3">
        <div class="flex items-center justify-between">
          <trac-input
            placeholder="Search"
            class="leading-7 w-65"
            data-test="terminal-phone-number"
            v-model="search"
          ></trac-input>
          <div class="flex items-center gap-3">
            <trac-dropdown
              title="Store Name"
              :menuItems="[{ name: 'All' }, ...stores]"
              class="w-full"
              v-model="filterStore"
            ></trac-dropdown>
          </div>
        </div>
        <div
          style="max-height: 60vh"
          class="border rounded-md border-gray-200 w-full shadow-md custom-scrollbar overflow-y-auto mt-6"
        >
          <table data-test_id="sales_agent_table" class="text-xs w-full">
            <caption class="sr-only">
              Sales Agent Table
            </caption>
            <thead
              class="bg-accentLight border-b border-gray-300 py-5 sticky top-0"
            >
              <tr>
                <th
                  class="text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                >
                  Name
                </th>
                <th
                  class="text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                >
                  Location
                </th>
                <th
                  class="text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                >
                  Unique Code
                </th>
                <th
                  class="text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                >
                  Actions
                </th>
                <th
                  class="text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody
              v-if="formatTableData && formatTableData.length"
              class="bg-white w-full"
            >
              <tr v-for="agent in formatTableData" :key="agent.id">
                <td
                  class="pl-6 text-left text-sm py-4 text-gray-800 font-medium text-opacity-75 uppercase"
                >
                  {{ agent.first_name }}
                  {{ agent.last_name }}
                </td>
                <td
                  class="pl-6 text-left text-sm py-4 text-gray-800 flex items-center gap-2 font-medium text-opacity-75 uppercase"
                >
                  <img src="../../assets/svg/location.svg" alt="" />
                  {{ agent.store }}
                </td>
                <td
                  class="pl-6 text-left text-sm py-4 text-gray-800 font-medium text-opacity-75 uppercase"
                >
                  {{ agent.sales_agent_code }}
                </td>
                <td
                  @click.stop
                  class="pl-6 text-left text-sm py-4 text-red-700 cursor-not-allowed font-medium text-opacity-75 uppercase"
                >
                  Delete
                </td>
                <td
                  @click.stop
                  class="pl-6 text-left text-sm py-4 font-medium text-opacity-75 uppercase"
                  :class="
                    checkStoreStatus(agent.store) === 'Enabled'
                      ? 'text-green-700 '
                      : 'text-gray-500'
                  "
                >
                  {{ checkStoreStatus(agent.store) }}
                </td>
              </tr>
            </tbody>
            <tbody class="bg-white w-full" v-else>
              <tr>
                <td
                  class="pl-6 text-sm text-gray-800 py-4 font-medium text-opacity-75 uppercase"
                >
                  There is no data available.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="sales-height flex items-center flex-col space-y-5 justify-center"
        v-else
      >
        <img src="../../assets/svg/agents.svg" alt="" />
        <h1 class="text-sm font-medium">Sales Agent not enabled</h1>
        <p class="text-sm text-gray-600 text-center">
          Create and track sales made by sales agent by assigning a unique code
          <br />
          to each sales agent
        </p>
        <trac-button @click.native="switchStoreModal = true" class="uppercase">
          Enable Sales Agent
        </trac-button>
      </div>
    </div>
    <trac-modal
      v-if="createSalesAgentModal"
      @close="createSalesAgentModal = false"
    >
      <div class="flex flex-col items-center justify-center py-6 px-32">
        <h1 class="text-sm font-medium">Create Sales Agent</h1>
        <div class="space-y-5 mt-6 w-full">
          <trac-input
            v-model="firstName"
            class="w-full"
            placeholder="First Name"
          ></trac-input>
          <trac-input
            v-model="lastName"
            class="w-full"
            placeholder="Last Name"
          ></trac-input>
          <trac-dropdown
            title="Store Name"
            :menuItems="stores"
            class="w-full"
            v-model="store"
          ></trac-dropdown>
        </div>
        <trac-button
          @click.native="createSalesAgents"
          :disabled="salesDisabled"
          class="uppercase mt-8 w-full"
        >
          Enable Sales Agent
        </trac-button>
      </div>
    </trac-modal>
    <trac-modal v-if="uniqueCodeModal" @close="uniqueCodeModal = false">
      <div class="flex flex-col items-center justify-center py-6 px-4">
        <h1 class="text-sm font-medium">Sales Agent Unique Code</h1>
        <p class="text-sm mt-5 text-gray-600 text-center">
          Copy code below and share with your employee(s) to get be able to
          complete payment for this store.
        </p>
        <div
          class="py-4 my-5 border border-dashed w-full bg-gray-100 px-5 flex items-center justify-between"
        >
          <div ref="message" class="text-sm">{{ code }}</div>
          <img
            @click="copyTextNoInput"
            class="cursor-pointer"
            src="../../assets/svg/copy.svg"
            alt=""
          />
        </div>
        <trac-button @click.native="refresh" class="uppercase mt-8 w-6/12">
          Close
        </trac-button>
      </div>
    </trac-modal>
    <trac-modal
      size="max-w-2xl "
      v-if="switchStoreModal"
      @close="switchStoreModal = false"
    >
      <h1 class="text-sm font-medium mt-2 border-b pb-5">
        Switch Stores ({{ stores.length }})
      </h1>
      <div>
        <div class="flex items-center justify-between mt-4">
          <trac-input
            placeholder="Search"
            class="leading-7 w-65"
            data-test="terminal-phone-number"
            v-model="storeSearch"
          ></trac-input>
          <div class="flex items-center gap-3">
            <p class="text-xs text-gray-600">Switch All</p>
            <trac-toggle
              @newval="toggleFeature"
              :active="active"
              class="text-sm font-semibold text-primaryBlue ml-1"
            ></trac-toggle>
          </div>
        </div>
        <div class="mt-5 border">
          <div
            class="flex items-center justify-between border-b last:border-0 bg-gray-100 p-4"
            v-for="(store, index) in formatToggleStores"
            :key="index"
          >
            <div class="text-xs uppercase">
              {{ store.name }}
            </div>
            <trac-toggle
              @newval="toggleUpdate(store)"
              :active="checkToggle(store)"
              class="text-sm font-semibold text-primaryBlue ml-1"
            ></trac-toggle>
          </div>
        </div>
        <div class="flex items-center justify-between w-full">
          <trac-button
            @click.native="updateStores"
            class="uppercase mt-8 w-6/12 mx-auto"
          >
            Done
          </trac-button>
        </div>
      </div>
    </trac-modal>
  </main>
</template>

<script>
import { eventBus } from '../../eventBus'
import { GET_USER_BUSINESS_ID } from '../../browser-db-config/localStorage'

export default {
  data() {
    return {
      agents: [],
      menuItems: [
        { name: 'filter1', value: 1 },
        { name: 'filter2', value: 2 },
        { name: 'filter3', value: 3 },
        { name: 'filter4', value: 4 },
      ],
      tableData: [],
      createSalesAgentModal: false,
      uniqueCodeModal: false,
      switchStoreModal: false,
      code: '',
      loading: false,
      stores: [],
      feature: false,
      firstName: '',
      lastName: '',
      store: '',
      salesDisabled: false,
      search: '',
      filterStore: '',
      selectAll: false,
      toggleStores: [],
      updatedStores: [],
      storeSearch: '',
    }
  },
  methods: {
    async toggleFeature() {
      let all = []
      this.toggleStores.forEach((item) => {
        all.push({
          id: item._id,
          is_enabled: !this.active ? 'true' : 'false',
        })
      })
      const payload = {
        key: 'SALES_AGENT_CODE',
        category: 'PAYMENT',
        scope: 'store',
        business_id: GET_USER_BUSINESS_ID(),
        updates: all,
      }
      try {
        await this.$store.dispatch('TOGGLE_FEATURES', payload)
        eventBus.$emit('trac-alert', {
          message: `Stores Updated Successfully`,
        })
        this.switchStoreModal = false
        this.selectAll = false
        this.initialize()
      } catch (error) {
        eventBus.$emit('trac-alert', {
          message: `There was an issue updating the store: ${error}`,
        })
      }
    },
    checkStoreStatus(value) {
      const exactStore = this.toggleStores?.find(
        (store) => store.name === value
      )

      const getExactFeature = exactStore.features.find(
        (item) => item.category === 'PAYMENT' && item.key === 'SALES_AGENT_CODE'
      )

      return getExactFeature?.is_enabled ? 'Enabled' : 'Disabled'
    },
    async updateStores() {
      const payload = {
        key: 'SALES_AGENT_CODE',
        category: 'PAYMENT',
        scope: 'store',
        business_id: GET_USER_BUSINESS_ID(),
        updates: this.updatedStores,
      }

      try {
        await this.$store.dispatch('TOGGLE_FEATURES', payload)
        eventBus.$emit('trac-alert', {
          message: `Stores Updated Successfully`,
        })
        this.switchStoreModal = false
        this.initialize()
      } catch (error) {
        eventBus.$emit('trac-alert', {
          message: `There was an issue updating the store: ${error}`,
        })
      }
    },
    checkToggle(store) {
      if (store.features && store.features.length) {
        const isValid = store.features.find(
          (item) =>
            item.category === 'PAYMENT' && item.key === 'SALES_AGENT_CODE'
        )
        if (isValid.is_enabled) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },

    toggleUpdate(val) {
      let idExists = false
      let newObj = {
        id: val._id,
      }

      if (!val.features) {
        newObj.is_enabled = 'true'
      } else {
        const isValid = val.features.find(
          (item) =>
            item.category === 'PAYMENT' && item.key === 'SALES_AGENT_CODE'
        )
        newObj.is_enabled =
          isValid && isValid?.is_enabled === true ? 'false' : 'true'
      }

      for (let i = 0; i < this.updatedStores.length; i++) {
        if (this.updatedStores[i].id === newObj.id) {
          this.updatedStores[i].is_enabled =
            this.updatedStores[i].is_enabled === 'true' ? 'false' : 'true'
          idExists = true
          break
        }
      }

      if (!idExists) {
        this.updatedStores.push(newObj)
      }
    },

    copyTextNoInput() {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard

      clipboardData.writeText(this.code)
    },

    async refresh() {
      await this.fetchSalesAgents()
      this.uniqueCodeModal = false
    },

    async fetchAllStores() {
      this.isLoading = true
      try {
        const res = await this.$store.dispatch('HARD_FETCH_ALL_STORES')
        return res
      } catch (error) {
        console.error('Error fetching stores:', error)
      } finally {
        this.isLoading = false
      }
    },

    async checkStatus() {
      try {
        const res = await this.$store.dispatch('FEATURE_TOGGLE')
        return res
      } catch (error) {
        console.error('Error checking feature toggle status:', error)
        return false
      }
    },

    async fetchSalesAgents() {
      try {
        const res = await this.$store.dispatch('GET_ALL_SALES_AGENT')
        if (res.status) {
          this.tableData = res?.data
        }
        return res
      } catch (error) {
        console.log(error)
      }
    },

    async createSalesAgents() {
      if (this.firstName === '' || this.lastName === '' || this.store === '') {
        eventBus.$emit('trac-alert', {
          message: 'First Name, Last Name And Store is required',
        })
        return
      }
      this.salesDisabled = true
      const getStoreId = this.stores.find((store) => store.name === this.store)

      try {
        const payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          store: getStoreId.value,
        }

        const res = await this.$store.dispatch('CREATE_SALES_AGENT', payload)

        if (res.status) {
          this.code = res?.data?.sales_agent_code
          this.firstName = ''
          this.lastName = ''
          this.store = ''
        }

        this.createSalesAgentModal = false
        this.uniqueCodeModal = true
      } catch (error) {
      } finally {
        this.salesDisabled = false
      }
    },

    async initialize() {
      try {
        this.loading = true
        const [storesResponse, statusResponse] = await Promise.all([
          this.fetchAllStores(),
          this.checkStatus(),
        ])

        if (storesResponse?.status) {
          let store = storesResponse.data
          this.toggleStores = store
          store = store
            .filter((item) => {
              if (item.features) {
                const enabled = item.features.find(
                  (salesCode) =>
                    salesCode.category === 'PAYMENT' &&
                    salesCode.key === 'SALES_AGENT_CODE'
                )
                return enabled?.is_enabled
              }
            })
            .map((item) => {
              return {
                name: item.name,
                value: item._id,
              }
            })
          this.stores = store
        }
        if (statusResponse.status) {
          this.feature = statusResponse?.data?.is_enabled
          if (this.feature) {
            await this.fetchSalesAgents()
          }
        }
      } catch (error) {
        console.error('Error in mounted:', error)
      } finally {
        this.loading = false
      }
    },
  },

  watch: {
    search(val) {
      if (val) {
        this.filterStore = ''
      }
    },
    // async selectAll(val) {

    // },
  },

  computed: {
    active() {
      const allFeaturesValid = this.formatToggleStores.every((item) => {
        if (Array.isArray(item.features) && item.features.length > 0) {
          return item.features.every((feature) => {
            return (
              feature.key === 'SALES_AGENT_CODE' && feature.is_enabled === true
            )
          })
        }
        return false
      })
      return allFeaturesValid
      // const allActive = this.formatToggleStores.filter((stores) => {
      //   console.log(stores, 'proper')
      // })
      // return true
    },
    formatTableData() {
      const data = this.tableData
        .map((store) => {
          const storeName = store.name
          if (store.sales_agents && Array.isArray(store.sales_agents)) {
            return store.sales_agents.map((agent) => ({
              ...agent,
              store: storeName,
            }))
          }
          return []
        })
        .reduce((acc, val) => acc.concat(val), [])

      if (this.filterStore === '' || this.filterStore === 'All') {
        return data.filter((agent) => {
          const searchLower = this.search.toLowerCase()
          return [
            agent.first_name,
            agent.last_name,
            agent.sales_agent_code,
          ].some((prop) => prop.toLowerCase().includes(searchLower))
        })
      } else {
        return data.filter((agent) => {
          return agent.store === this.filterStore
        })
      }
    },

    formatToggleStores() {
      return this.toggleStores.filter((store) => {
        const searchLower = this.storeSearch.toLowerCase()
        return [store.name].some((prop) =>
          prop.toLowerCase().includes(searchLower)
        )
      })
    },
  },

  async mounted() {
    await this.initialize()
  },
}
</script>

<style scoped>
.sales-height {
  height: 50vh;
}
.remove-spacing {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
